import React from 'react'
import Img from 'gatsby-image'
import { T } from '../../typo'
import { s, globals, colors } from '../../style'
import { WrapperSection, WrapperContainer } from '../../wrappers'
import { Grid } from '@material-ui/core'
import SideLine from '../../elements/SideLine/SideLine'
import ReactMarkdown from 'react-markdown'

const AboutMission = ({ data, theme }) => {
  // console.log(data)
  const { title, slug_section, image, about_sections } = data

  return (
    <WrapperSection
      id={slug_section}
      outside={<SideLine theme={theme}>{title}</SideLine>}>
      <WrapperContainer
        theme={theme}
        fullMobile
        extraCss={sContainer}
        outside={<SideLine theme={theme}>{title}</SideLine>}>
        {about_sections?.map(
          ({ title, subtitle, description, image, body }, id) => (
            <div css={sSpace} key={id}>
              <Grid container key={id}>
                <Grid
                  item
                  xs={12}
                  md={6}
                  lg={7}
                  css={[
                    id % 2 === 1 ? sSection(theme).odd : sSection(theme).even,
                  ]}>
                  <div
                    key={id}
                    css={[
                      sSection(theme),
                      id !== about_sections.length - 1 &&
                        sSection(theme).spacing,
                    ]}>
                    <T
                      d={32}
                      m={24}
                      mb={0.25}
                      bold
                      className="about-title"
                      variant="h4">
                      {title}
                    </T>
                    <T
                      o={0.5}
                      d={14}
                      m={14}
                      mb={2}
                      bold
                      spacious
                      upper
                      variant="h5"
                      className="about-subtitle">
                      {subtitle}
                    </T>
                    <T o={0.5} mb={2} m={14} normal className="about-desc">
                      {body ? (
                        <ReactMarkdown>{body}</ReactMarkdown>
                      ) : (
                        description
                      )}
                    </T>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  lg={5}
                  css={[
                    { [s.md]: { order: id % 2 === 0 ? 1 : 0 } },
                    sImageContainer,
                  ]}>
                  <Img
                    style={sImageContainer.wrapper}
                    imgStyle={sImageContainer.imgStyle}
                    fluid={image?.childImageSharp?.fluid}
                    alt={image?.name}
                  />
                </Grid>
              </Grid>
            </div>
          )
        )}
      </WrapperContainer>
    </WrapperSection>
  )
}

const sContainer = {
  [s.sm_down]: {
    paddingBottom: globals.spacing.inside.mobile,
  },
  [s.md]: {
    paddingBottom: globals.spacing.inside.desktop,
  },
}

const sSection = (theme) => ({
  '.about-title, .about-subtitle': {
    color: colors[theme].main,
  },

  [s.sm_down]: {
    paddingBottom: globals.spacing.inside.mobile,
  },

  spacing: {
    [s.sm_down]: {
      paddingBottom: globals.spacing.inside.mobile,
    },
    [s.md]: {
      paddingBottom: globals.spacing.inside.desktop,
    },
  },

  even: {
    'li::marker': {
      color: colors.main,
    },
    '.about-desc': {
      [s.md]: {
        marginRight: '4rem',
      },
    },
  },

  odd: {
    'li::marker': {
      color: colors.main,
    },
    [s.md]: {
      order: 1,
      '.about-title': {
        textAlign: 'right',
      },
    },
    '.about-desc': {
      [s.md]: {
        marginRight: '0rem',
        marginLeft: '4rem',
      },
    },
  },
})

const sSpace = {
  [s.sm_down]: {
    '&:not(last-of-type)': { paddingBottom: globals.spacing.inside.mobile * 2 },
  },
  [s.md]: {
    '&:not(last-of-type)': {
      paddingBottom: globals.spacing.inside.desktop * 2,
    },
  },
}

const sImageContainer = {
  flexShrink: 1,
  width: 320,
  height: 320,
  position: 'relative',
  borderRadius: '4rem',

  wrapper: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: '100%',
    height: '100%',
    borderRadius: 'inherit',
  },
  imgStyle: {},
}

export default AboutMission
